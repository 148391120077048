/**
* A product can be a real article from different type of vending machines or charging money.
*             A product can also be a article or resource supplied from a service company to a device operator
*             (e.g. automated resource supplies based on usage tracking)
*             The <see cref="T:Iovent.Model.Products.ProductType" /> may influence the way a product is presented, e.g. in the product editor.
*/
export enum ProductType {
    Unknown = "Unknown",
    /**
    * The default case: A real product, which is dispensed from a vending machine,
    *             like a cup of coffee or a sandwich. When possible, prefer the more specialiced
    *             types below.
    */
    Article = "Article",
    /**
    * A real product, which the user can take from a trust-based shelf.
    *             These products don't require any serving. Users get the products themselves.
    */
    SelfService = "SelfService",
    /** Loading money on a closed-loop card. */
    Charging = "Charging",
    /**
    * Product dispensed by the <see cref="F:Iovent.Model.Devices.Config.Machine.MachineType.Eversys" /> coffee machine, like a cappuccino,
    *             espresso, milk foam or hot water.
    */
    Eversys = "Eversys",
    /** Product dispensed by the <see cref="F:Iovent.Model.Devices.Config.Machine.MachineType.WMF" /> coffee machine, like a cappuccino */
    WMF = "WMF",
    /** Product dispensed by the <see cref="F:Iovent.Model.Devices.Config.Machine.MachineType.SandenVendoVM" /> vending machine. */
    SandenVendoVM = "SandenVendoVM",
    /**
    * Resource used by the machine or device
    *             no explicit unit
    */
    Resource = "Resource",
    /**
    * Resource for bean hoppers and powder chute
    *             unit: mg bzw. kg
    */
    Resource_Beans = "Resource_Beans",
    /**
    * Resource for milk feeds
    *             unit: ml bzw. l
    */
    Resource_Milk = "Resource_Milk",
    /**
    * Resource for powder dispensers
    *             unit: mg bzw. kg
    */
    Resource_Powder = "Resource_Powder"
}
